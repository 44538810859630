import React, { useCallback, useState, useEffect, useRef } from 'react'
import emailjs from '@emailjs/browser'
import { createPortal } from "react-dom"
import styles from './ModalPresentation.module.scss'
import {ModalOverlay} from '../ModalOverlay/ModalOverlay'
import logo from '../../image/Header/logo.png'
import { FormValidation } from '../../hooks/FormValidation'
import InputMask from "react-input-mask"


const ModalPresentation = ({isPresentationClose, popupOpen}) => {

    const modalRoot = document.getElementById("modals");
    
    const handleForm = FormValidation()
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const form = useRef()

    useEffect(() => {
        handleForm.isValid ? setButtonDisabled(false) : setButtonDisabled(true)
    }, [handleForm.isValid])

    const sendData = useCallback((evt) => {
        evt.preventDefault();
            emailjs.sendForm('service_5fv7r14', 'template_gi4ej8s', form.current, 'qI9g0u7y-NVxRlXXq')
            .then((result) => {
            }, (error) => {
                console.log(error.text);
            }); 
            isPresentationClose()
        handleForm.resetForm()
        popupOpen()
    }, [handleForm, isPresentationClose, popupOpen] )

    useEffect(() => {
        const handleEscClose = (evt) =>{
            if (evt.key ==='Escape')  isPresentationClose()
        }
        document.addEventListener('keydown', handleEscClose)
        return () => document.removeEventListener('keydown', handleEscClose)
    }, [isPresentationClose])

    return createPortal (
        <ModalOverlay onClick={isPresentationClose}>
            <div className={styles.modal} onClick={evt => evt.stopPropagation()}>
                <div className={styles.overlay}>
                <div className={styles.box_logo}>
                    <img className={styles.logo} src={logo} alt='логотип'/>
                    <button data-test="close-button" className={styles.close_icon} type="button" aria-label="закрыть" onClick={isPresentationClose}/>
                </div>
                <div className={styles.box}>
                    <h2 className={styles.title}>Заполните данные, через несколько секунд каталог уже будет у вас</h2>
                        <form className={styles.form} onSubmit={sendData} ref={form}>
                            <div className={styles.box_input}>
                                <label className={styles.label}>Введите имя
                                    <input
                                        className={`${styles.input} ${handleForm.errors.name && styles.wrong}`}
                                        type='text' 
                                        name='name'
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange} 
                                        pattern='^[A-Za-zА-Яа-яЁё\s\-]{2,30}$'
                                        required/>
                                </label>
                            </div>
                            <span className={styles.input_error}>{handleForm.errors.name}</span>
                            <div className={styles.box_input}>
                                <label className={styles.label}>Введите телефон      
                                    <InputMask
                                        className={`${styles.input} ${handleForm.errors.phone && styles.wrong}`}
                                        type='tel' 
                                        name='phone'
                                        mask={'+7\\(999) 999-99-99'}
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange}  
                                        pattern='\+7\s?[\(][0-9]{3}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}'
                                        required/>
                                </label>
                            </div>            
                            <span className={styles.input_error}>{handleForm.errors.phone}</span>
                                    <button className={`${styles.button_form} ${buttonDisabled && styles.button_disabled}`}type='submit'><span>скачать каталог</span></button>
                        </form>
                </div>
                </div>
                <p className={styles.text}>Нажимая «скачать каталог», вы соглашаетесь с политикой конфиденциальности.</p>
            </div>
        </ModalOverlay>
        ,modalRoot
    );
};

export default ModalPresentation