import React from 'react'
import styles from './DescriptionComplex.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Autoplay} from "swiper"
import image from '../../image/DescriptionComplex/img.png'
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"

const DescriptionComplex = () => {

    return (
        <section className={styles.main} id='proekt'>
            <div className={styles.box}>
                <div className={styles.slider}>
                <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
            >
                <SwiperSlide>
                <div className={styles.item}>
                    <div className={styles.box_text}>
                        <h3  className={styles.item_title}>Коротко о комплексе</h3>
                        <p className={styles.item_text}>Жилой комплекс «НЕБО» – три башни на 1300 квартир, с потрясающими видовыми характеристиками и продуманной инфраструктурой. В состав проекта входит три небоскреба — 51-52-этажные монолитные дома с большой площадью остекления. Комплекс состоит из корпусов Family, Tandem и Solo подземного паркинга на 940 машиномест и детского сада. Квартиры в башнях Family и Tandem предлагаются без отделки, в башне Solo с отделкой whitebox и под ключ.</p>
                    </div>
                        <div className={styles.box_image}>
                            <img className={styles.image} src={image} alt='мальчик'/>
                            <div className={styles.image_info}>
                                <h3 className={styles.image_title}>Виктория Уланова</h3>
                                <p className={styles.image_text}>эксперт проекта</p>
                            </div>
                        </div>
                    </div>  
                </SwiperSlide>
                <SwiperSlide>
                <div className={styles.item}>
                    <div className={styles.box_text}>
                        <h3  className={styles.item_title}>Расположение</h3>
                        <p className={styles.item_text}>Жилой комплекс «НЕБО» расположен в районе Раменки, на берегу реки, рядом с природным парком. Выезд на Мичуринский проспект расположен в 450 м от комплекса. По этой трассе можно доехать до Третьего транспортного кольца (ТТК) примерно за 10-15 минут в зависимости от дорожной обстановки. При отсутствии пробок путь до центральных столичных улиц займет около 20 минут. В 5 минутах от жилого комплекса открыта станция метро Раменки.</p>
                    </div>
                        <div className={styles.box_image}>
                            <img className={styles.image} src={image} alt='мальчик'/>
                            <div className={styles.image_info}>
                                <h3 className={styles.image_title}>Виктория Уланова</h3>
                                <p className={styles.image_text}>эксперт проекта</p>
                            </div>
                        </div>
                    </div>  
                </SwiperSlide>
                <SwiperSlide>
                <div className={styles.item}>
                    <div className={styles.box_text}>
                        <h3  className={styles.item_title}>Инфраструктура</h3>
                        <p className={styles.item_text}>На территории жилого комплекса «НЕБО» расположились детские  спортивные площадки, тихие зоны для прогулок. Вся инфраструктура приподнята над землей, что создает атмосферу приватности и защищает от чужих взглядов. Недалеко от комплекса парк 50-летия Октября, река Раменка, парк Олимпийской деревни, МГУ им. М. В. Ломоносова и природный заказник Воробьевы горы. Раменки считаются престижным местом для жизни с соответствующей ценой квадратного метра.</p>
                    </div>
                        <div className={styles.box_image}>
                            <img className={styles.image} src={image} alt='мальчик'/>
                            <div className={styles.image_info}>
                                <h3 className={styles.image_title}>Виктория Уланова</h3>
                                <p className={styles.image_text}>эксперт проекта</p>
                            </div>
                        </div>
                    </div>  
                </SwiperSlide>
            </Swiper>  
                </div>
                
            </div>
        </section>
    )
}

export default DescriptionComplex

