import React from 'react'
import styles from './Main.module.scss'
import PhoneCoffee from '../../components/PhoneCoffee/PhoneCoffee'
import Ipoteka from '../../components/Ipoteka/Ipoteka'
import ObjectsNearby from '../../components/ObjectsNearby/ObjectsNearby'
import InitialPage from '../../components/initialPage/InitialPage'
import Developer from '../../components/Developer/Developer'
import DescriptionComplex from '../../components/DescriptionComplex/DescriptionComplex'
import ProjectGallery from '../../components/ProjectGallery/ProjectGallery'
import InteriorDecoration from '../../components/InteriorDecoration/InteriorDecoration'
import Map from '../../components/Map/Map'
import Planning from '../../components/Planning/Planning'
import PresentationСomplex from '../../components/PresentationСomplex/PresentationСomplex'
import AlbomPlanning from '../../components/AlbomPlanning/AlbomPlanning'


const Main = ({isOpen, isImageOpen, isInteriorsOpen, isPresentationOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage/>
            <ProjectGallery
                isImageOpen={isImageOpen}
            />
            <DescriptionComplex/>
            <Planning
                isOpen={isOpen}
            />
            <AlbomPlanning
                isPresentationOpen={isPresentationOpen}
            />
            <PhoneCoffee
                isOpen={isOpen}
            />
            <InteriorDecoration
                isImageOpen={isInteriorsOpen}
            />
            <PresentationСomplex
                isPresentationOpen={isPresentationOpen}
            />
            <ObjectsNearby/>
            <Developer/>
            <Ipoteka
                isOpen={isOpen}
            />
            <Map/>
        </section>
    )
}

export default Main