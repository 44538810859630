import React, {useState, useEffect, useCallback} from 'react'
import { motion } from "framer-motion"
import styles from './Developer.module.scss'
import { arrDeveloper } from '../../utils/data'
import image from '../../image/Developer/logo.svg'
import { v4 as uuidv4 } from 'uuid'
import ScreenSize from '../../hooks/ScreenSize'

const titleAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const textAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .8}
    } 
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}

const Developer = () => {

    const width = ScreenSize()
    const [moviesTotal, setMoviesTotal] = useState(0)
    const [addMovies, setAddMovies] = useState(0)


    useEffect(() => {
        const getCards = () => {
            if (width >= 960) {
                setMoviesTotal(6);
                setAddMovies(3);
                } else if (width < 960 && width >= 630) {
                    setMoviesTotal(4);
                    setAddMovies(2);
                        } else if (width <= 629) {
                            setMoviesTotal(3);
                            setAddMovies(1);
                            }
        }
        getCards();
    }, [width])

    const addCards = useCallback(() => {
        setMoviesTotal(moviesTotal + addMovies);
    }, [moviesTotal, addMovies])

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.box}>
                <motion.img className={styles.image} src={image} alt='логотип' variants={titleAnimation}/>
                <motion.p className={styles.text} variants={textAnimation}>Capital Group — одна из крупнейших российских девелоперских компаний. На протяжении 30 лет специализируется на строительстве самых сложных и знаковых объектов Москвы – многофункциональных комплексов, жилой и коммерческой недвижимости.</motion.p>
                <motion.ul className={styles.list} variants={boxAnimation}>
                {arrDeveloper.map((item, index) =>{
                                const keyUid = uuidv4()
                                if (index + 1 <= moviesTotal) {
                                    
                                    return(
                                        <li className={styles.item} key={keyUid}>
                                            <img className={styles.item_image}  src={item.image} alt={item.title}/>
                                            <div className={styles.item_info}>
                                                <h3 className={styles.item_title} >{item.title}</h3>
                                                <p className={styles.item_text}>{item.text}</p>
                                            </div>
                                        </li>
                                    ) 
                                } else {
                                    return ''
                                }
                            })}     
                </motion.ul>
                {moviesTotal < arrDeveloper.length && 
                (<div className={styles.button_box}>
                    <button className={styles.button} onClick={addCards}>еще объекты</button>
                </div>)}
            </div>
        </motion.section>
    )
}

export default Developer