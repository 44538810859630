import oil_1 from '../image/Loading/img_1.webp'
import oil_2 from '../image/Loading/img_2.webp'
import oil_3 from '../image/Loading/img_3.webp'
import oil_4 from '../image/Loading/img_4.webp'
import oil_5 from '../image/Loading/img_5.webp'
import oil_6 from '../image/Loading/img_6.webp'
import oil_7 from '../image/Loading/img_7.webp'
import oil_8 from '../image/Loading/img_8.webp'
import oil_9 from '../image/Loading/img_9.webp'

import dev_1 from '../image/Developer/img_1.webp'
import dev_2 from '../image/Developer/img_2.webp'
import dev_3 from '../image/Developer/img_3.webp'
import dev_4 from '../image/Developer/img_4.webp'
import dev_5 from '../image/Developer/img_5.webp'
import dev_6 from '../image/Developer/img_6.webp'

import img_1 from '../image/ProjectGallery/img_1.webp'
import img_2 from '../image/ProjectGallery/img_2.webp'
import img_3 from '../image/ProjectGallery/img_3.webp'
import img_4 from '../image/ProjectGallery/img_4.webp'
import img_5 from '../image/ProjectGallery/img_5.webp'


import img_1_1 from '../image/ProjectGallery/Lobby/img_1.webp'
import img_2_1 from '../image/ProjectGallery/Lobby/img_2.webp'
import img_3_1 from '../image/ProjectGallery/Lobby/img_3.webp'
import img_4_1 from '../image/ProjectGallery/Lobby/img_4.webp'
import img_5_1 from '../image/ProjectGallery/Lobby/img_5.webp'

import img_1_2 from '../image/ProjectGallery/Interiors/img_1.webp'
import img_2_2 from '../image/ProjectGallery/Interiors/img_2.webp'
import img_3_2 from '../image/ProjectGallery/Interiors/img_3.webp'
import img_4_2 from '../image/ProjectGallery/Interiors/img_4.webp'
import img_5_2 from '../image/ProjectGallery/Interiors/img_5.webp'

import img_1_3 from '../image/ProjectGallery/Kinds/img_1.webp'
import img_2_3 from '../image/ProjectGallery/Kinds/img_2.webp'
import img_3_3 from '../image/ProjectGallery/Kinds/img_3.webp'
import img_4_3 from '../image/ProjectGallery/Kinds/img_4.webp'
import img_5_3 from '../image/ProjectGallery/Kinds/img_5.webp'

import int_1 from '../image/InteriorDecoration/Bedroom/img_1.webp'
import int_2 from '../image/InteriorDecoration/Bedroom/img_2.webp'
import int_3 from '../image/InteriorDecoration/Bedroom/img_3.webp'
import int_4 from '../image/InteriorDecoration/LivingRoom/img_1.webp'
import int_5 from '../image/InteriorDecoration/LivingRoom/img_2.webp'
import int_6 from '../image/InteriorDecoration/LivingRoom/img_3.webp'
import int_7 from '../image/InteriorDecoration/Kitchen/img_1.webp'
import int_8 from '../image/InteriorDecoration/Kitchen/img_2.webp'
import int_9 from '../image/InteriorDecoration/Kitchen/img_3.webp'
import int_10 from '../image/InteriorDecoration/Bathroom/img_1.webp'
import int_11 from '../image/InteriorDecoration/Bathroom/img_2.webp'
import int_12 from '../image/InteriorDecoration/Bathroom/img_1.webp'

import pla_1 from '../image/Planning/studi.webp'
import pla_1_49 from '../image/Planning/1_49.webp'
import pla_2_74 from '../image/Planning/2_74.webp'
import pla_3_83 from '../image/Planning/3_83.webp'
import pla_3_107 from '../image/Planning/3_107.webp'
import pla_4_134 from '../image/Planning/4_134.webp'

export const arrInteriors = [{image: int_1, title: 'ЖК Небо интерьеры'}, {image: int_2, title: 'ЖК Небо интерьеры'}, {image: int_3, title: 'ЖК Небо интерьеры'}, {image: int_4, title: 'ЖК Небо интерьеры'}, {image: int_5, title: 'ЖК Небо интерьеры'}, {image: int_6, title: 'ЖК Небо интерьеры'}, {image: int_7, title: 'ЖК Небо интерьеры'}, {image: int_8, title: 'ЖК Небо интерьеры'}, {image: int_9, title: 'ЖК Небо интерьеры'}, {image: int_10, title: 'ЖК Небо интерьеры'}, {image: int_11, title: 'ЖК Небо интерьеры'}, {image: int_12, title: 'ЖК Небо интерьеры'},];

export const arrOneRoom = [{img: pla_1_49, apartment: '1 спальня', price: '23 000 000', status: 'Без отделки', size: '49',}, {apartment: '1 спальня', price: '24 800 000', status: 'WhiteBox', size: '48',}, {apartment: '1 спальня', price: '25 000 000', status: 'Без отделки', size: '50',},  {apartment: '1 спальня', price: '25 000 000', status: 'WhiteBox', size: '48',}, {img: pla_1_49,apartment: '1 спальня', price: '27 000 000', status: 'WhiteBox', size: '49',}, {apartment: '1 спальня', price: '27 900 000', status: 'WhiteBox', size: '48',}, {img: pla_1_49, apartment: '1 спальня', price: '28 500 000', status: 'WhiteBox', size: '49',}, {apartment: '1 спальня', price: '29 000 000', status: 'WhiteBox', size: '48',}, {img: pla_1_49, apartment: '1 спальня', price: '29 600 000', status: 'С отделкой', size: '49',}, {apartment: '1 спальня', price: '29 900 000', status: 'WhiteBox', size: '50',}, {apartment: '1 спальня', price: '30 600 000', status: 'Без отделки', size: '51',}, {img: pla_1_49, apartment: '1 спальня', price: '32 500 000', status: 'С отделкой', size: '49',}, {apartment: '1 спальня', price: '32 500 000', status: 'Без отделки', size: '52',}, {apartment: '1 спальня', price: '37 000 000', status: 'С отделкой', size: '50',}, {apartment: '1 спальня', price: '40 000 000', status: 'С отделкой', size: '67',}, {apartment: '1 спальня', price: '42 000 000', status: 'С отделкой', size: '78',},];

export const arrTwoRooms = [{apartment: '2 спальни', price: '30 800 000', status: 'Без отделки', size: '69',}, {apartment: '2 спальни', price: '31 000 000', status: 'Без отделки', size: '70',}, {apartment: '2 спальни', price: '31 600 000', status: 'С отделкой', size: '65',}, {apartment: '2 спальни', price: '31 900 000', status: 'Без отделки', size: '71',}, {img: pla_2_74, apartment: '2 спальни', price: '33 900 000', status: 'Без отделки', size: '74',}, {apartment: '2 спальни', price: '34 700 000', status: 'Без отделки', size: '75',}, {img: pla_2_74, apartment: '2 спальни', price: '35 700 000', status: 'WhiteBox', size: '74',}, {apartment: '2 спальни', price: '36 700 000', status: 'Без отделки', size: '77',}, {apartment: '2 спальни', price: '37 000 000', status: 'WhiteBox', size: '79',}, {apartment: '2 спальни', price: '37 900 000', status: 'Без отделки', size: '85',}, {apartment: '2 спальни', price: '39 000 000', status: 'WhiteBox', size: '81',}, {apartment: '2 спальни', price: '41 000 000', status: 'WhiteBox', size: '85',}, {apartment: '2 спальни', price: '42 000 000', status: 'WhiteBox', size: '80',}, {apartment: '2 спальни', price: '42 000 000', status: 'Без отделки', size: '84',}, {apartment: '2 спальни', price: '43 000 000', status: 'WhiteBox', size: '85',}, {apartment: '2 спальни', price: '43 800 000', status: 'С отделкой', size: '78',}, {apartment: '2 спальни', price: '45 500 000', status: 'С отделкой', size: '84',}, {apartment: '2 спальни', price: '49 500 000', status: 'С отделкой', size: '82',}, {apartment: '2 спальни', price: '54 500 000', status: 'С отделкой', size: '85',}, {apartment: '2 спальни', price: '66 700 000', status: 'С отделкой', size: '108',},];

export const arrThreeRooms = [{img: pla_3_83, apartment: '3 спальни', price: '41 000 000', status: 'Без отделки', size: '83',}, {apartment: '3 спальни', price: '41 800 000', status: 'Без отделки', size: '84',}, {img: pla_3_83, apartment: '3 спальни', price: '43 500 000', status: 'Без отделки', size: '83',}, {img: pla_3_107, apartment: '3 спальни', price: '44 500 000', status: 'Без отделки', size: '107',},  {apartment: '3 спальни', price: '46 450 000', status: 'WhiteBox', size: '103',}, {img: pla_3_107, apartment: '3 спальни', price: '47 000 000', status: 'Без отделки', size: '107',}, {apartment: '3 спальни', price: '49 900 000', status: 'Без отделки', size: '109',}, {apartment: '3 спальни', price: '52 000 000', status: 'Без отделки', size: '108',}, {img: pla_3_107,apartment: '3 спальни', price: '52 900 000', status: 'WhiteBox', size: '107',}, {apartment: '3 спальни', price: '53 000 000', status: 'Без отделки', size: '108',}, {apartment: '3 спальни', price: '57 000 000', status: 'Без отделки', size: '109',}, {apartment: '3 спальни', price: '57 300 000', status: 'Без отделки', size: '109',}, {apartment: '3 спальни', price: '59 000 000', status: 'Без отделки', size: '108',}, {img: pla_3_107, apartment: '3 спальни', price: '64 000 000', status: 'С отделкой', size: '107',}, {img: pla_3_107, apartment: '3 спальни', price: '64 500 000', status: 'С отделкой', size: '107',}, {apartment: '3 спальни', price: '64 400 000', status: 'Без отделки', size: '140',}, {apartment: '3 спальни', price: '69 000 000', status: 'С отделкой', size: '109',},];

export const arrFourRooms = [{apartment: '4 спальни', price: '61 000 000', status: 'Без отделки', size: '136',}, {apartment: '4 спальни', price: '61 000 000', status: 'Без отделки', size: '135',}, {img: pla_4_134, apartment: '4 спальни', price: '61 400 000', status: 'Без отделки', size: '134',}, {apartment: '4 спальни', price: '64 900 000', status: 'Без отделки', size: '137',}, {apartment: '4 спальни', price: '65 500 000', status: 'Без отделки', size: '136',}, {apartment: '4 спальни', price: '66 500 000', status: 'Без отделки', size: '140',}, {apartment: '4 спальни', price: '68 000 000', status: 'Без отделки', size: '136',}, {apartment: '4 спальни', price: '69 900 000', status: 'Без отделки', size: '136',}, {apartment: '4 спальни', price: '71 500 000', status: 'Без отделки', size: '136',}, {apartment: '4 спальни', price: '71 400 000', status: 'Без отделки', size: '134',}, {apartment: '4 спальни', price: '72 900 000', status: 'Без отделки', size: '137',}, {img: pla_4_134, apartment: '4 спальни', price: '73 900 000', status: 'WhiteBox', size: '134',}, {img: pla_4_134, apartment: '4 спальни', price: '75 000 000', status: 'WhiteBox', size: '134',}, {apartment: '4 спальни', price: '80 000 000', status: 'WhiteBox', size: '133',}, {apartment: '4 спальни', price: '80 000 000', status: 'Без отделки', size: '136',}, {apartment: '4 спальни', price: '125 000 000', status: 'С отделкой', size: '207',}, {apartment: '4 спальни', price: '135 000 000', status: 'С отделкой', size: '156',},];

export const arrArchitecture = [{image: img_1, title: 'ЖК Небо архитектура'}, {image: img_2, title: 'ЖК Небо архитектура'}, {image: img_3, title: 'ЖК Небо архитектура'}, {image: img_4, title: 'ЖК Небо архитектура'}, {image: img_5, title: 'ЖК Небо архитектура'}, {image: img_1_1, title: 'ЖК Небо архитектура'}, {image: img_2_1, title: 'ЖК Небо архитектура'}, {image: img_3_1, title: 'ЖК Небо архитектура'}, {image: img_4_1, title: 'ЖК Небо архитектура'}, {image: img_5_1, title: 'ЖК Небо архитектура'}, {image: img_1_2, title: 'ЖК Небо архитектура'}, {image: img_2_2, title: 'ЖК Небо архитектура'}, {image: img_3_2, title: 'ЖК Небо архитектура'}, {image: img_4_2, title: 'ЖК Небо архитектура'}, {image: img_5_2, title: 'ЖК Небо архитектура'}, {image: img_1_3, title: 'ЖК Небо архитектура'}, {image: img_2_3, title: 'ЖК Небо архитектура'}, {image: img_3_3, title: 'ЖК Небо архитектура'}, {image: img_4_3, title: 'ЖК Небо архитектура'}, {image: img_5_3, title: 'ЖК Небо архитектура'},];

export const arrObjects = [{image: oil_1, title: 'МГУ', text: 'лучший университет',}, {image: oil_2, title: 'Долина реки сетунь', text: 'природный заказник',}, {image: oil_3, title: 'Лужники', text: 'стадион',}, {image: oil_4, title: 'Воробьевы горы', text: 'природный заказник',}, {image: oil_5, title: 'Поклонная гора', text: 'мемориальный парк',}, {image: oil_6, title: 'Москва Сити', text: 'деловой центр',}, {image: oil_7, title: 'Событие', text: 'парк отдыха',}, {image: oil_8, title: 'Нескучный сад', text: 'парк отдыха',}, {image: oil_9, title: '50-летия Октября', text: 'парк отдыха',},];


export const arrDeveloper = [{image: dev_1, title: 'Capital Towers', text: 'жилой комплекс',}, {image: dev_2, title: 'The Book', text: 'жилой комплекс',}, {image: dev_3, title: 'Аэробус', text: 'жилой комплекс',}, {image: dev_4, title: 'Медный 3.14', text: 'жилой комплекс',}, {image: dev_5, title: 'Созвездие Капитал-1', text: 'жилой комплекс',}, {image: dev_6, title: 'White Khamovniki', text: 'жилой комплекс',},];

export const arrApartment = [{img: pla_1, apartment: 'Студия', price: '16 000 000', status: 'Без отделки', size: '27',}, {apartment: 'Студия', price: '16 700 000', status: 'Без отделки', size: '28',}, { apartment: 'Студия', price: '18 000 000', status: 'Без отделки', size: '28',},{img: pla_1, apartment: 'Студия', price: '20 400 000', status: 'С отделкой', size: '27',}, {apartment: 'Студия', price: '21 700 000', status: 'Без отделки', size: '30',}, {apartment: 'Студия', price: '24 800 000', status: 'С отделкой', size: '30',},];

