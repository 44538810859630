import React from 'react'
import { motion } from "framer-motion"
import styles from './Map.module.scss'
import DetailMap from '../DetailMap/DetailMap'

const titleAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const textAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .8}
    } 
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}


const Map = () => {


    return (
        <motion.section 
            className={styles.main}
            id='raspolozhenie'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Расположение комплекса</motion.h2>
                <motion.p className={styles.text} variants={textAnimation}>Жилой комплекс «Небо» расположен по адресу Москва, Мичуринский проспект, 56. Запишитесь на ознакомительную экскурсию через партнёрский сайт продаж.</motion.p>
                <motion.div className={styles.box} variants={boxAnimation}>
                    <DetailMap/>
                </motion.div>
            </div>
        </motion.section>
    )
}

export default Map