import React from 'react'
import { motion } from "framer-motion"
import styles from './PresentationСomplex.module.scss'
import image from '../../image/PresentationСomplex/img.webp'
import image_1 from '../../image/PresentationСomplex/img_1.webp'
import wp from '../../image/PresentationСomplex/wp.svg'
import tg from '../../image/PresentationСomplex/tg.svg'

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}

const PresentationСomplex = ({isPresentationOpen}) => {

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <motion.div className={styles.box} variants={boxAnimation}>
                <div className={styles.box_text}>
                    <h2 className={styles.title}>Презентация комплекса</h2>
                    <p className={styles.text}>Расположение корпусов, архитектура, планировки и цены на квартиры в жилых небоскрёбах в нашей PDF-презентации.</p>
                    <button className={styles.button} type='button' onClick={isPresentationOpen}>Скачать на сайте</button>
                    <a className={styles.link} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79932507445' aria-label='связаться вацап'>
                                <img src={wp} alt="Whatsapp"/>
                                <span>Получить в Whatsapp</span>
                            </a>
                            <a className={styles.link_telegram} target='_blank' rel='noopener noreferrer' href='https://t.me/+79932507445' aria-label='телеграмм'>
                                <img src={tg} alt="Whatsapp"/>
                                <span>Получить в telegram</span>
                            </a>
                </div>
                <img className={styles.image} src={image} alt='презентация'/>
                <img className={styles.image_min} src={image_1} alt='презентация'/>
            </motion.div>
        </motion.section>
    )
}

export default PresentationСomplex