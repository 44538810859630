import React from 'react'
import styles from './DetailMap.module.scss'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'
import metro from '../../image/DetailMap/metro.svg'
import person from '../../image/DetailMap/person.svg'
import car from '../../image/DetailMap/car.svg'
import nebo from '../../image/DetailMap/nebo.png'

const DetailMap = () => {


    return (
        <div className={styles.main}>
            <div className={styles.box}>
                <div className={styles.box_item}>
                    <div className={styles.box_icon}>
                        <img className={styles.icon} src={metro} alt='метро'/>
                        <img className={styles.icon} src={person} alt='метро'/>
                    </div>
                    <p className={styles.text}>Раменки - 10 минут</p>
                </div>
                <div className={styles.box_item}>
                    <img className={styles.icon} src={car} alt='машина'/>
                    <p className={styles.text}>Садовое кольцо – 23 минуты</p>
                </div>
                <div className={styles.box_item}>
                    <img className={styles.icon} src={car} alt='машина'/>
                    <p className={styles.text}>ТТК – 17 минут</p>
                </div>
                <div className={styles.box_item}>
                    <img className={styles.icon} src={car} alt='машина'/>
                    <p className={styles.text}>МКАД – 12 минут</p>
                </div>
            </div>
            <div className={styles.box_map}>
                    <YMaps>
                        <Map width='100%' height='100%' defaultState={{ center: [55.695671, 37.485535], zoom: 14}}>
                            <Placemark 
                                geometry={[55.695671, 37.485535]} 
                                    options={{
                                        iconLayout: 'default#image',
                                        iconImageSize: [40, 57],
                                        iconImageHref: nebo,
                                        }}
                                    />
                        </Map>
                    </YMaps>
            </div>

        </div>
    )
}

export default DetailMap